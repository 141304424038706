import React from 'react'
import Helmet from 'react-helmet'
import { graphql, PageRendererProps } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { Block, Content, Flex, FlexSpacer } from 'gerami'
import moment from 'moment'
import {
  FaFacebook,
  FaTelegram,
  FaTwitter,
  FaWhatsapp,
  FaArrowLeft
} from 'react-icons/fa'
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share'

import { BlogPostByIdQuery } from '../../../graphql-types'
import useSiteMetadata from '../../shared/hooks/use-site-metadata/use-site-metadata'
import App from '../app'
import Layout from '../../shared/components/layout/layout'
import Anchor from '../../shared/components/anchor/anchor'

type BlogPostProps = PageRendererProps & {
  data: BlogPostByIdQuery
}

const BlogPost: React.FC<BlogPostProps> = ({ location, data }) => {
  const { markdownRemark: blogPost } = data

  const siteMetadata = useSiteMetadata()
  const url = `${siteMetadata.siteUrl}${location.pathname}`
  const appName = siteMetadata?.title

  const thumbnail = blogPost?.frontmatter?.thumbnail
  const title = blogPost?.frontmatter?.title!
  const date = blogPost?.frontmatter?.date!
  const authors = blogPost?.frontmatter?.authors!
  const body = blogPost?.html!
  const excerpt = blogPost?.excerpt!

  return (
    <App pageRendererProps={{ location }}>
      <Helmet titleTemplate="%s | Blog">
        <title>{title}</title>
      </Helmet>

      <Layout>
        {thumbnail && (
          <GatsbyImage
            fluid={{
              aspectRatio: 4 / 3,
              src: `${thumbnail}?nf_resize=fit&w=1024`,
              srcSet: `
              ${thumbnail}?nf_resize=fit&w=1024 1024w,
              ${thumbnail}?nf_resize=fit&w=640 640w,
              ${thumbnail}?nf_resize=fit&w=320 320w
            `,
              sizes: `
              (min-width: 36em) 33.3vw,
              100vw
            `
            }}
            className="bg-whitish"
            style={{ maxHeight: `81vh` }}
          />
        )}

        <Content transparent size="4XL" className="margin-top-big">
          <Block first className="font-S padding-bottom-none">
            <Flex>
              <span className="margin-vertical-auto">
                <Anchor to="/blog">
                  <small>
                    <FaArrowLeft />
                  </small>
                  <span className="inline-block margin-left-normal">Blog</span>
                </Anchor>
              </span>

              <FlexSpacer />

              <span className="margin-vertical-auto fg-blackish right">
                <small className="inline-block middle margin-small margin-right-normal">
                  Share:
                </small>

                <FacebookShareButton
                  className="margin-horizontal-normal font-L middle"
                  url={url}
                  quote={`${title} | Blog – ${appName}\n\n${excerpt}`}
                  hashtag={
                    appName ? `#${appName.replace(/ /g, '')}` : undefined
                  }
                >
                  <FaFacebook />
                </FacebookShareButton>

                <TelegramShareButton
                  className="margin-horizontal-normal font-L middle"
                  url={url}
                  title={`${title} | Blog – ${appName}: ${excerpt}`}
                >
                  <FaTelegram />
                </TelegramShareButton>

                <TwitterShareButton
                  className="margin-horizontal-normal font-L middle"
                  url={url}
                  title={`${title} | Blog – ${appName}`}
                  hashtags={appName ? [appName.replace(/ /g, ``)] : undefined}
                >
                  <FaTwitter />
                </TwitterShareButton>

                <WhatsappShareButton
                  className="margin-horizontal-normal font-L middle"
                  url={url}
                  title={`${title} | Blog – ${appName}: ${excerpt}`}
                >
                  <FaWhatsapp />
                </WhatsappShareButton>
              </span>
            </Flex>
          </Block>
        </Content>

        <Content transparent size="XL">
          <Block last>
            <h1 className="h0 margin-top-very-big margin-bottom-small">
              {title}
            </h1>
            <div className="subtitle">
              Published {moment(new Date(date)).fromNow()} by{' '}
              {authors.join(`, `)}
            </div>

            <hr className="block margin-vertical-very-big" />

            <article dangerouslySetInnerHTML={{ __html: body }} />
          </Block>
        </Content>
      </Layout>
    </App>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      frontmatter {
        thumbnail
        isFeatured
        title
        date(formatString: "MMMM DD, YYYY")
        authors
      }
      html
    }
  }
`
